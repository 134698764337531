












































import { Component, Prop, Vue } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import DxDateBox from "devextreme-vue/date-box";
import {
  format,
  subDays,
  isWithinInterval,
  Interval,
  parseISO,
  isAfter,
  isBefore,
  parse,
} from "date-fns";
import { BaseDto } from "@/shared/dtos/base-dto";
import { data_sensors } from "@/shared/dtos/data_sensors";

@Component({
  components: {
    VueApexCharts,
    DxDateBox,
  },
})
export default class test extends Vue {
  public date_1 = new Date();
  public date_2 = new Date();

  public filtros: any = null;
  public log: any[] = [];
  public created() {
    this.date_1 = subDays(new Date(), 15);
    this.date_2 = new Date();
  }

  public clear() {}
  public get DataSerie() {
    this.log = [];
    var series: any[] = [];
    this.log.push("empieza");
    for (let i = 0; i < this.datos_sensores_charts.length; i++) {
      let datos_sensores = this.datos_sensores_charts[i];
      let sensor = "";
      let dataSerie = [];

      this.log.push("DataSet");
      //hay que poner un try sino no te lo pinta
      var fechas_controlar = [];
      try {
        var inter: Interval = { start: this.date_1, end: this.date_2 };
        for (let i = 0; i < datos_sensores.length; i++) {
          var date = datos_sensores[i].fecha;
          var date_parse = parse(
            date.replaceAll("/", "-"),
            "dd-MM-yyyy HH:mm:ss",
            new Date()
          );
          this.log.push(
            "Date " +
              date +
              "Date parse " +
              date_parse +
              " i = " +
              i +
              " total: " +
              datos_sensores.length
          );
          //isWithinInterval(date_parse, inter)
          if (
            isAfter(date_parse, this.date_1) &&
            isBefore(date_parse, this.date_2)
          ) {
            fechas_controlar.push(new data_sensors(datos_sensores[i]));
            this.log.push("Pasa");
            /*if (Number.parseFloat(fechas_controlar[i].dato) <= 1) {
              dataSerie.push(0);
              sensor = fechas_controlar[i].sensor;
            } else {
              dataSerie.push(Number.parseFloat(fechas_controlar[i].dato));
              sensor = fechas_controlar[i].sensor;
            }*/
            try {
              dataSerie.push(new data_sensors(datos_sensores[i]).fecha);
            } catch (error) {
                           this.log.push(error);
            }
          }
        }
        //this.filtros = fechas_controlar;
      } catch {}
      series.push({
        name: sensor,
        data: dataSerie.map((x) => x.toString()),
      });
    }
    return series;
  }

  public isAfter(d1: Date, d2: Date) {
    return d1 < d2;
  }
  public isBefore(d1: Date, d2: Date) {
    return d1 > d2;
  }

  public datos_sensores_charts = [
    [
      {
        id: 1,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "83,34",
        fecha: "20/12/2021 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 2,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "84,34",
        fecha: "21/12/2021 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 3,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "85,34",
        fecha: "22/12/2021 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 4,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "86,34",
        fecha: "23/12/2021 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 7,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "25/02/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 8,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "26/02/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 9,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "27/02/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 10,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "28/02/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 11,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "01/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 12,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "02/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 13,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "03/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 14,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "04/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 15,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "05/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 16,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "06/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 17,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "07/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 18,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "08/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 19,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "09/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 20,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "10/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 21,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "11/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 22,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "12/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 23,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "13/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 24,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "14/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 25,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "15/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 26,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "16/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 27,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "17/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 28,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "18/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 29,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "19/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 30,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "20/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 31,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "21/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 32,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "22/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 33,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "23/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 34,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "24/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 35,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "25/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 36,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "26/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 37,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "27/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 38,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "28/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 39,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "29/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 40,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "30/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 41,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "31/03/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 42,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "01/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 43,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "02/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 44,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "03/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 45,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "04/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 46,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "05/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 47,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "06/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 48,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "07/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 49,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "08/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 50,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "09/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 51,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "10/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 52,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "11/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 53,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "12/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 54,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "13/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 55,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "14/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 56,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "15/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 57,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "16/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 58,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "17/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 59,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "18/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 60,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "19/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 61,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "20/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 62,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "21/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 63,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "22/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 64,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "23/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 65,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "24/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 66,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "25/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 67,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "26/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 68,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "27/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 69,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "28/04/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 70,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "01/05/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 71,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "02/05/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 72,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "03/05/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 73,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "04/05/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 74,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "05/05/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 75,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "06/05/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 76,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "07/05/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
      {
        id: 77,
        dispositivo: "SEN_AUX",
        recurso: "cc55e110-115a-43aa-a2da-ba7c9e1beb20",
        sensor: "volumenRegado",
        dato: "0",
        fecha: "08/05/2022 0:00:00",
        id_grupo: null,
        unique_id: 0,
      },
    ],
  ];
}
