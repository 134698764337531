import { BaseDto } from "@/shared/dtos/base-dto";

export class data_sensors extends BaseDto {
  public dispositivo!: string;
  public recurso!: string;
  public sensor!: string;
  public dato!: string;
  public fecha!: string;
  public id_grupo!: string;
}
